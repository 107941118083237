.autoToolTip,
.shrinkWrap {
    display: inline !important;
    .bp3-popover-target {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    padding: 0;
    * {
        padding: 0;
    }
}

.autoToolTipContents{
    padding: 5px;
    max-width: 500px;
}
