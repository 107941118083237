@import "../styles/Colors.module.scss";
@import "../styles/Mixins.scss";
@import "../styles/Params.scss";

.bp3-dialog.password {
    @include centered();
    gap: 10px;
    background-color: $hmiBackDrop !important;
    padding: 20px;
    height: 150px;
    width: 450px;
}
.dialogbox {
    background-color: $hmiBackDrop;
    padding: 20px;
    width: 320px;
    height: 290px;
    text-align: justify;

    .buttons {
        margin-left: 150px;
        padding-top: 10px;
        display: flex;
        .bp3-button {
            margin-left: 5px;
        }
    }
}
.disclaimer {
    display: flex;
    .bp3-checkbox {
        padding-top: 1px;
    }
    label {
        white-space: pre;
        font-size: small;
    }
    .disclaimLabel {
        color: blue;
        cursor: pointer;
        user-select: none;
    }
    .disclaimLabel:hover {
        text-decoration: underline;
    }
}

.loginScreen {
    height: 100vh;
    width: 100vw;
    @include robotBackground($includeLogo: false);
}

.loginBox {
    @include shadowed();
    background-color: #fff;
    background-color: rgba(255,255,255,0.75);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: fit-content;
    width: 450px;
    border: 1px solid black;
    border-radius: $autoBorderRadius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    gap: 20px;

    .bp3-input-group {
        margin-top: -18px;
        width: 303px;
        input {
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom-style: groove;
            background-color: #fff;
            background-color: rgba(255,255,255,0.75);
            box-shadow: none;
            margin-top: 10px;
        }
    }

    button {
        background-color: $autoDarkBlue;
        cursor: pointer;
        color: white;
        font-size: larger;
        border: none;
        padding: 20px;
        width: 270px;
    }
    button:hover {
        background-color: lighten($autoDarkBlue, 10);
    }
    button:active {
        background-color: lighten($autoDarkBlue, 20);
    }

    .appTitle {
        display: grid;
        grid-template-columns: max-content 1fr;
        column-gap: 30px;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        h1 {
            //border: 1px solid red;
            text-align: center;
        }
    }

    span {
        text-align: center;
        color: rgb(148, 12, 12);
    }

    .blueLoad {
        height: 100vh;
        width: 100vw;
        background-color: $autoDarkBlue;
    }
}
