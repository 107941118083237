@import "../../Auto/styles/Colors.module.scss";
@import "../../Auto/styles/Mixins.scss";

.umbilicalFlushing {

    .card{
        overflow-y: scroll;
    }
    .leftPane {
        display: grid;
        height: 100%;
        grid-template-rows: min-content 1fr;
        grid-row: 1 / -1;
        gap: $cardGridGap;
        max-height: calc(100vh - #{2 * $cardGridGap});
    }


        .inputs {
            @include expandValueWithUnit();
            display: grid;
            grid-template-columns: max-content 1fr 80px;
            row-gap: 4px;
            .injectionHeader {
                grid-column: 1/-1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                margin-top: 10px;
            }
            .injectionInfo {
                display: contents;
                .collapseGroup .header {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    border: none;
                    padding-top: 7px;
                    h2 {
                        font-size: 11pt;
                    }
                    svg{
                        height: 10px;
                    }
                }
        
            }
        }

        .calcModePicker {
            grid-column: 1 / -1; 
            .bp3-control {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    
    .results{
        height: 100%;
        width: 100%;
     .contents {
        height: 100%;
            width: 100%;
        display: grid;
        grid-template-rows: min-content min-content 1fr min-content;
        overflow-y: hidden;

        .bp3-slider {
            width: 80%;
        }

        .timeSlider {
            display: flex;
            justify-content: center;
            gap: 25px;
            margin-bottom: 25px;
            .bp3-button {
                height: min-content;
            }
        }
    }
    * {
        .svg-container {
            background-color: red;
            height: 100% !important; //this fixes Plotly not shrinking with window
            width: 100% !important; //this fixes Plotly not shrinking with window
        }
    }
}
}
