@import "../../../Auto/styles/Params.scss";
@import "../../../Auto/styles/Mixins.scss";
@import "../../../Auto/styles/Colors.module.scss";

.autoPlot {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr max-content;
    position: relative;
    .bp3-button {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .plotArea {
        position: relative;
        height: 100%;
        width: 100%;
        //overflow-y: hidden;
    }

    //modebar
    .modebar-group {
        background-color: transparent !important;
    }
    .modebar-btn {
        path {
            fill: $autoDarkBlue !important;
            opacity: 0.5;
        }
    }
    .modebar-btn:hover{
        path{
            fill: $autoOrange !important;
            opacity: 1;
        }
    }
    .modebar-btn.active{
        path {
            fill: $autoDarkBlue !important;
            opacity: 0.8;
        }
    }
    .tracebar {
        display: flex;
        flex-direction: right;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 3px;
        padding: 10px 0;
        .traceItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            gap: 2px;
            padding: 5px;
            border: 1px solid transparent;
            border-radius: 3px;
            input {
                width: 10ch;
                background-color: white;
            }
            input:not(:focus-within) {
                background-color: transparent;
                border-color: transparent;
            }
            .traceColor {
                width: 25px;
                height: 3px;
            }
            .bp3-icon {
                svg {
                    fill: transparent;
                }
                cursor: pointer;
            }
        }
        .traceItem:hover {
            border: 1px solid $autoBorderTextColor;
            .bp3-icon {
                svg {
                    fill: $autoBorderTextColor;
                }
            }
        }
    }

    * {
        .svg-container {
            height: 100% !important; //this fixes Plotly not shrinking with window
            width: 100% !important; //this fixes Plotly not shrinking with window
        }
    }
}
