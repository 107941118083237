$autoAqua: #37998F;
$autoBad: rgb(173, 0, 0);
$autoBlue: #0004b7;
$autoLightBlue: #5c5cff;
$autoDarkBlue: #06233f;
$autoOrangeHover: #fcd69c;
$autoGrayHover: #c5c5d6;
$hmiBackDrop: #f7f7f7;
$autoBorderTextColor: #1a1a33;
$autoOrange1: #f7ad41;
$autoOrange2: #f99a2c;
$autoOrange: $autoOrange2;
$autoGray: #5a5a5c;
$autoPurest: white;
$autoYellow: #ffef00;

:export {
    autoAqua: $autoAqua;
    autoBad: $autoBad;
    autoBlue: $autoBlue;
    autoLightBlue: $autoLightBlue;
    autoDarkBlue: $autoDarkBlue;
    autoOrangeHover: $autoOrangeHover;
    autoGrayHover: $autoGrayHover;
    hmiBackDrop: $hmiBackDrop;
    autoBorderTextColor: $autoBorderTextColor;
    autoOrange1: $autoOrange1;
    autoOrange2: $autoOrange2;
    autoOrange: $autoOrange;
    autoGray: $autoGray;
    autoPurest:$autoPurest;
    autoYellow: $autoYellow;
}
