@import '../../Auto/styles/Colors.module.scss';

.collapseGroup {
    $hoverColor: $autoLightBlue;
    display: contents;
    .header {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        cursor: pointer;
        h2 {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        border-bottom: 1px solid black;
        margin-bottom: 10px;
    }
    .header:hover {
        color: $hoverColor;
        fill: $hoverColor;
        border-color: $hoverColor;
        svg {
            fill: $hoverColor;
        }
    }
    .header.bottom{
        border: none;
        height: 10px;
    }
}