@import "./Colors.module.scss";

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}
::-webkit-scrollbar-thumb {
    min-width: 5px;
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: $autoDarkBlue;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb:vertical {
    min-height: 30px;
}

::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

