@import "../styles/Colors.module.scss";

$border: 5px solid transparent;
.autoModeBar {
    display: flex;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    .barOption {
        cursor: pointer;
        font-size: 14pt;
        display: flex;
        justify-content: center;
        width: 100%;
        border-bottom: $border;
    }
    .barOption:hover:not(.selected) {
        border-color: $autoOrangeHover;
    }
    .barOption.selected {
        font-weight: 700;
        border-color: $autoOrange;
    }
}

.autoModeBar.vertical {
    flex-direction: column;
    margin: auto 0;
    height: 50%;
    width: fit-content;
    .barOption {
        height: 100%;
        align-items: center;
        width: fit-content;
        padding-left: 10px;
        border-bottom: none;
        border-left: $border;
    }
    .barOption.selected {
        border-color: $autoOrange;
    }
}

.autoModeBar.noSelectBorder{
    .barOption{
        border: none;
        padding-left: unset;
    }
}