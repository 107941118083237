@import "../../styles/Colors.module.scss";

$sidebarWidth: 300px;
$transition: 0.3s;
.sidebarWrapper {
    width: 100%;
    height: 100%;
    .sidebar {
        display: flex;
        position: fixed;
        height: 100%;
        width: $sidebarWidth;
        transition: transform $transition;
        z-index: 5;

        .bp3-icon {
            color: $autoBorderTextColor;
        }

        .contents {
            position: relative;
            display: grid;
            grid-template-rows: 40px min-content 1fr min-content;
            padding-top: 10px;
            gap: 10px;
            background-color: $hmiBackDrop;
            height: 100%;
            width: 100%;

            .header {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                height: 100%;
                align-items: center;
                gap: 10px;
                padding: 0 10px;
            }
        }
        .borderBar {
            width: 20px;
            background-color: $autoOrange;
        }
        .logos {
            cursor: pointer;
            $gap: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 0 $gap;
            gap: $gap;
            img,
            svg {
                max-height: 100%;
                max-width: 100%;
            }
        }
        .logos:hover {
            background-color: $autoOrangeHover;
        }
        .links {
            max-height: 100%;
            overflow: auto;
        }
        .bottomItems {
            //box-sizing: content-box;
            width: 100%;
            .afsInfo {
                svg {
                    max-height: 40px;
                    width: 40px;
                }
            }
            .utilityButtons {
                display: flex;
                flex-direction: row;
                button {
                    width: 100%;
                }
            }
        }
    }
    .appContent {
        height: 100vh;
        padding-left: $sidebarWidth;
        transition: padding-left $transition;
    }
}
.sidebarWrapper.closed {
    .sidebar {
        transform: translateX(-$sidebarWidth);
    }
    .appContent {
        padding-left: 0;
    }
}
.sidebarCollapseGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .collapseGroupChildren {
        margin-left: 20px;
        border-left: 1px solid $autoGray;
    }
}

.sidebarItem {
    cursor: pointer;
    height: 32px;
    font-size: medium;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    label {
        cursor: inherit;
        display: flex;
        gap: 10px;
    }
    img, svg {
        max-height: 20px;
        width: 20px;
    }
}
.sidebarItem.selected,
.sidebarItem:hover {
    background-color: $autoOrangeHover;
}
