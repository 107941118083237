@import "../../Auto/styles/Colors.module.scss";
@import "../../Auto/styles/Params.scss";
@import "../../Auto/styles/Mixins.scss";

.hydrateCurves {
    h4 {
        margin: 0;
    }

    .card{
        overflow-y: scroll;
    }
    .leftPane {
        display: grid;
        height: 100%;
        grid-template-rows: min-content 1fr;
        grid-row: 1 / -1;
        gap: $cardGridGap;
        max-height: calc(100vh - #{2 * $cardGridGap});
    }

    .compositionTable {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 5px;
        //align-items: center;

        .compositionLabel {
            margin: 7px 0px;
        }
    }

    .inhibitorBar {
        margin-top: 2px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 25px;
    }

    .salinity {
        width: 100%;
        .salinityBar {
            @extend .inhibitorBar;
            grid-template-columns: 3fr 2fr 1fr;
            margin-bottom: 10px;
            .bp3-button{
                align-self: center;
                width: 10px;
                height: 10px;
            }
        }
    }

    .discretizationGroup {
        display: flex;
        input {
            max-width: 80px;
        }
        justify-content: space-between;
        margin-bottom: 20px;
    }

    form {
        margin-bottom: 20px;
    }

    $traceAreaHeight: 200px;

    .hydratePlot {
        height: 100%;
        width: 100%;
        .contents {
            display: grid;
            grid-template-rows: 1fr $traceAreaHeight;
            height: 100%;
            width: 100%;
            overflow-y: hidden;
        }
        .chartUnits {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
        }
        .chartControl {
            max-height: $traceAreaHeight;
            display: grid;
            grid-template-rows: min-content 1fr;
            .plotArea {
                position: relative;
                height: 100%;
                width: 100%;
            }
            .traceArea {
                margin: 10px;
                max-height: 50%;
                display: flex;
                flex-wrap: wrap;
                overflow-y: auto;
                pre {
                    white-space: pre-wrap;
                }
            }
        }
        * {
            .svg-container {
                background-color: red;
                height: 100% !important; //this fixes Plotly not shrinking with window
                width: 100% !important; //this fixes Plotly not shrinking with window
            }
        }
    
    }

    .traceCard {
        background: white;
        span {
            margin-right: 5px;
            user-select: none;
        }
        display: flex;
        align-items: center;
        width: max-content;
        border: 1px solid black;
        //border-radius: 5px;
        margin: 10px 5px;
        padding: 5px 15px;
    }
    .traceCard:hover {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
        border-color: blue;
        cursor: pointer;
    }
}

.submitBtn{
    .bp3-button {
        box-sizing: border-box;
        border: $cardBorderThickness solid $autoOrange;
        background-color: $hmiBackDrop !important;
        color: $autoBorderTextColor;
        box-shadow: none;
        @include shadowed;
        border-radius: $autoBorderRadius;
        font-size: 16pt;
        height: 50px;
        width: 100%;
    }
    
    .bp3-button:hover {
        @extend .bp3-button;
        border-color: darken($autoOrange, 10%);
    }
    
    .bp3-button:active {
        @extend .bp3-button:hover;
        background-color: darken($hmiBackDrop, 5%) !important;
        border-color: darken($autoOrange, 20%);
    }
    
    .bp3-button:disabled {
        @extend .bp3-button;
        border-color: dimgray;
        color: dimgray;
        background-color: darken($hmiBackDrop, 30%) !important;
    }
}

.addBtn{
    .bp3-button{
        width: 100%;
    }
}