@import '../../styles/Colors.module.scss';

.formikRadioGroup{
    display: flex;
    flex-direction: column;
    gap: 5px;
    label{
        margin: 0;
    }
    .bp3-control input:checked ~ .bp3-control-indicator{
        background-color: $autoGray;
        outline: none;
    }
}

.formikRadioGroup.inline{
    flex-direction: row;
}