@import "./Params.scss";

@mixin centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin robotBackground($includeLogo: true) {
    @if $includeLogo {
        background: url("../img/RobotWithAuto.svg") center no-repeat;
    } @else {
        background: url("../img/RobotOnly.svg") center no-repeat;
        background-size: 105%;
        opacity: 0.3;
    }
}

@mixin shadowed {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

@mixin expandValueWithUnit {
    display: grid;
    grid-template-columns: 1fr 100px max-content;
    row-gap: 5px;

    .collapseGroup {
        .header {
            grid-column: 1 / -1;
        }
    }
    .valueWithUnit {
        display: contents;
    }
    .autoField {
        display: contents;
        .bp3-form-group {
            display: contents;
        }
    }
    .autoSelectLabeled {
        display: contents;
        label {
            grid-column: 1/1;
        }
        .bp3-html-select {
            grid-column: 2/-1;
        }
    }
    .autoSelect{
        display: contents;
        .bp3-html-select{
            grid-column: 1/-1;
        }
    }
}

@mixin leftPane {
    display: grid;
    height: 100%;
    grid-template-rows: min-content 1fr;
    grid-row: 1 / -1;
    gap: $cardGridGap;
    max-height: calc(100vh - #{2 * $cardGridGap});
}

@mixin standardFont {
    /* .card {
        select,
        option {
            font-size: 14pt;
        }
        .editableLabel input,
        span:not(.bp3-control-indicator),
        label:not(.bp3-control) {
            font-size: 20pt;
        }
        .formikRadioGroup label {
            font-size: 18px;
        }
    } */
}
