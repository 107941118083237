@import "../styles/Colors.module.scss";
@import "../styles/Mixins.scss";

.loadingScreen {
    @include robotBackground();
    width: 100vw;
    height: 100vh;
    background-color: white;
    $barBottom: 30px;
    .loadingBar,
    label {
        position: absolute;
        bottom: 0;
        width: 50vw;
        bottom: $barBottom;
        left: 25%;

        .bp3-progress-meter {
            background-color: $autoOrange !important;
        }
    }
    label {
        @include centered();
        font-size: large;
        height: 50px;
        
        bottom: $barBottom + 8px;
        background-color: transparentize(white, 0.3);
    }
}
