@import '../../Auto/styles/Mixins.scss';

.notYetImplemented {
    $width: 350px;
    @include centered();
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width: 100%;
    .explainer{
        text-align: center;
        @include centered();
        flex-direction: column;
        gap: 10px;
    }
    .nav{
        width: $width;
    }
    .card {
        width: $width;
        height: min-content;
    }
}
