@import '../../Auto/styles/Colors.module.scss';

.plainTable{
    *{
        user-select: text;
    }
    border:1px solid black;
    border-collapse: collapse;
    text-align: center;
    thead{
        color: white;
        background-color: $autoDarkBlue;
        span{
            padding: 0px 5px;
        }
    }

    th, td{
        border:1px solid $autoGrayHover;
    }

    th{
        padding: 5px;
    }
    
    td{
        padding: 0px 10px;
        font-weight: 400;
    }
}