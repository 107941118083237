@import '../../../styles/Mixins.scss';

.valueWithUnit {
    display: flex;
    gap: 5px;
    .bp3-label {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    p {
        display: flex;
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        font-weight: 400;
        align-items: center;
        margin: 0;
        color: rgba(0, 0, 0, 0.8);
    }
}

.valueUnitGrid{
    @include expandValueWithUnit();
}