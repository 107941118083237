.bp3-slider-progress.bp3-intent-primary {
    background-color: $autoGray !important;
}

.bp3-button.bp3-intent-primary {
    box-sizing: border-box;
    border: $cardBorderThickness solid $autoOrange;
    background-color: $hmiBackDrop !important;
    color: $autoBorderTextColor;
    box-shadow: none;
    @include shadowed;
    border-radius: $autoBorderRadius;
}

.bp3-button.bp3-intent-primary:hover {
    @extend .bp3-button.bp3-intent-primary;
    border-color: darken($autoOrange, 10%);
}

.bp3-button.bp3-intent-primary:active {
    @extend .bp3-button.bp3-intent-primary:hover;
    background-color: darken($hmiBackDrop, 5%) !important;
    border-color: darken($autoOrange, 20%);
}

.bp3-button.bp3-intent-primary:disabled {
    @extend .bp3-button.bp3-intent-primary;
    border-color: dimgray;
    color: dimgray;
    background-color: darken($hmiBackDrop, 30%) !important;
}

.bp3-slider .bp3-slider-handle {
    //background-color: $autoOrange;
    //box-shadow: none;
    //border: 1px solid black;
    //background-image: none;
    //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.bp3-slider-handle:hover,
.bp3-slider-handle:active {
    //@extend .bp3-slider-handle;
}

.bp3-button.bp3-intent-primary {
    height: 50px;
    font-size: 16pt;
}
