@import "Colors.module.scss";
@import "Params.scss";
@import "Mixins.scss";
@import "Scrollbars.scss";

@import "Auto.bp3-custom.scss";
@import "Auto.plotly-custom.scss";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";

* {
    outline: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

*:not(input, textarea) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

*:focus {
    outline: none;
}

.app {
    a {
        color: inherit;
        text-decoration: inherit;
    }
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: $autoDarkBlue;
}

h2 {
    margin-top: 0;
    margin-bottom: 8px;
}

.card {
    @include shadowed;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: $hmiBackDrop;
    border-radius: $autoBorderRadius;
    padding: $cardPadding;
    overflow: hidden;
}

.cardGrid {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: $cardGridGap;
    background-color: $autoDarkBlue;
    display: grid;
    gap: $cardGridGap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

.explainer {
    padding-top: 10px;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    p {
        font-size: 12pt;
        font-weight: normal;
    }
    sub {
        font-size: 8pt;
        font-weight: normal;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

table {
    th {
        font-size: medium;
    }
    td {
        font-size: 16pt;
    }
}
