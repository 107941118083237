@import "../../Auto/styles/Params.scss";

.brineDensity {
    .card{
        overflow-y: visible;
    }

    .leftPane {
        display: grid;
        height: 100%;
        grid-template-rows: min-content 1fr;
        grid-row: 1 / -1;
        gap: $cardGridGap;
        max-height: calc(100vh - #{2 * $cardGridGap});
    }

    .itemGrid {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 2fr 1fr min-content;
        align-items: center;
        row-gap: 10px;
        column-gap: 5px;
        h2 {
            grid-column: span 3;
            margin: 10px 0px;
        }
        label{
            margin-bottom: 15px;
        }
        .bp3-input-group{
            width: 60px;
        }
        margin-bottom: 20px;
    }

    button{
        margin-right: 5px;
        border-color: black;
    }

    .brineResults {
        .contents {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .plainTable {
            width: 100%;
            margin-bottom: 30px;
        }
        .controlBar {
            width: 100%;
            align-items: flex-end;
            padding-bottom: 15px;
            display: flex;
            justify-content: space-around;
        }
    }

    .tip {
        margin-top: 10px;
        font-size: smaller;
    }
}
