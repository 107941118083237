@import "../../styles/Colors.module.scss";
@import "../../styles/Params.scss";

$transitionDuration: 0.1s;

.nav {
    position: relative;
    background-color: $hmiBackDrop;
    border-radius: 20px;
    border: $cardBorderThickness solid $autoOrange;
    cursor: pointer;
    //transition: width $transitionDuration, height $transitionDuration;
    height: 50px;
    svg {
        height: 65%;
    }
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-size: 14pt;
    font-weight: 500;
    overflow: hidden;

    .menuIcon {
        position: absolute;
        left: 5px;
    }
}
