.appBlocker {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(50, 50, 50, 0.9);
    z-index: 1;
    backdrop-filter: blur(2px);
    margin-top: -20px;
    margin-left: -20px;
    
    .nav{
        width: 330px;
        color: black;
        text-shadow: none;
    }
}

.appBlockerText{
    user-select: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    color: white;
    text-shadow: 2px 2px rgb(20, 20, 20);
}