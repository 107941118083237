@import "../../Auto/styles/Colors.module.scss";
@import "../../Auto/styles/Params.scss";
@import "../../Auto/styles/Mixins.scss";

.ssPipeline {
    overflow: hidden !important;
    .tallCard {
        overflow-y: visible;
    }

    .leftPane {
        display: grid;
        height: 100%;
        grid-template-rows: min-content 1fr;
        grid-row: 1 / -1;
        gap: $cardGridGap;
        max-height: calc(100vh - #{2 * $cardGridGap});
    }

    .itemGrid {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 2fr 1fr min-content;
        align-items: center;
        row-gap: 10px;
        column-gap: 5px;
        h2 {
            grid-column: span 3;
            margin: 10px 0px;
        }
        margin-bottom: 20px;
        label{
            margin-bottom: 15px;
        }
        .bp3-input-group{
            width: 60px;
        }
    }

    button {
        margin-right: 5px;
    }

    .ssPipelineResults {
        .contents {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .plainTable {
            width: 80%;
            margin-bottom: 30px;
        }
        .controlBar {
            width: 100%;
            align-items: flex-end;
            padding-bottom: 15px;
            display: flex;
            justify-content: space-around;
        }
    }

    .tip {
        font-size: smaller;
    }

    $traceAreaHeight: 200px;

    .ssPipelinePlot {
        height: 100%;
        width: 100%;
        .contents {
            display: grid;
            grid-template-rows: 1fr $traceAreaHeight;
            height: 100%;
            width: 100%;
            overflow-y: hidden;
        }
        .chartUnits {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
        }
        .chartControl {
            margin-top: 20px;
            max-height: $traceAreaHeight;
            display: grid;
            grid-template-rows: min-content 1fr;
            .plotArea {
                position: relative;
                height: 100%;
                width: 100%;
            }
            .traceArea {
                margin: 10px;
                max-height: 50%;
                display: flex;
                flex-direction: right;
                flex-wrap: wrap;
                overflow-y: auto;
                pre {
                    white-space: pre-wrap;
                }
            }
        }
        * {
            .svg-container {
                background-color: red;
                height: 100% !important; //this fixes Plotly not shrinking with window
                width: 100% !important; //this fixes Plotly not shrinking with window
            }
        }
    }

    .traceCard {
        background: white;
        span {
            margin-right: 5px;
            user-select: none;
        }
        display: flex;
        align-items: center;
        width: max-content;
        border: 1px solid black;
        //border-radius: 5px;
        max-height: 40px;
        margin: 10px 5px;
        padding: 5px 15px;
    }
    .traceCard:hover {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
        border-color: blue;
        cursor: pointer;
    }
}

.submitBtn {
    .bp3-button {
        box-sizing: border-box;
        border: $cardBorderThickness solid $autoOrange;
        background-color: $hmiBackDrop !important;
        color: $autoBorderTextColor;
        box-shadow: none;
        @include shadowed;
        border-radius: $autoBorderRadius;
        font-size: 16pt;
        height: 50px;
        width: 100%;
    }

    .bp3-button:hover {
        @extend .bp3-button;
        border-color: darken($autoOrange, 10%);
    }

    .bp3-button:active {
        @extend .bp3-button:hover;
        background-color: darken($hmiBackDrop, 5%) !important;
        border-color: darken($autoOrange, 20%);
    }

    .bp3-button:disabled {
        @extend .bp3-button;
        border-color: dimgray;
        color: dimgray;
        background-color: darken($hmiBackDrop, 30%) !important;
    }
}
