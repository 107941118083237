.infoLabel {
    display: flex;
    align-items: center;
    gap: 5px;
}

.infoIcon {
    svg {
        height: 12px;
        width: 12px;
    }
}

.infoIcon.large{
    svg{
        height: 16px;
        width: 16px;
    }
}

.labeledTooltipContents {
    h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 700;
    }
    max-width: 250px;
}
