@import "../../Auto/styles/Mixins.scss";

.flowRegime {
    .card{
        overflow-y: visible;
    }
    .bp3-label {
        width: 140px;
    }
    .bp3-input-group{
        width: 100px;
    }
    .newInline{
        display: flex;
        gap: 80px;
        align-items: center;
        
    }
    .plotArea{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        width: 100%;
        height: 100%;
        align-items: center;

        .plots {
            aspect-ratio: 1 / 1;
            object-fit: contain;
          }
        
      
    }
    .bp3-form-helper-text{
        width: 100px;
    }
}
