@import "../../styles/Colors.module.scss";

.autoField {
    input {
        text-align: center;
    }
    /*     .bp3-form-helper-text{
        font-size: x-small;
    } */
}

.editableLabel {
    input{
        font-size: 20pt;
    }
    input:not(:focus-within):not(:hover) {
        font-weight: 500;
        outline: none;
        box-shadow: none;
        background-color: transparent;
    }
}