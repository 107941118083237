@import "../styles/Colors.module.scss";
@import "../styles/Mixins.scss";
@import "../styles/Params.scss";

.errorBoundary {
    @include centered();
    background-color: $autoDarkBlue;
    height: 100vh;
    width: 100%;
    .errorBoundaryContainer {
        display: grid;
        grid-template-rows: min-content 1fr;
        gap: $cardGridGap;
        align-items: center;
        justify-content: center;
        .card {
            @include centered();
            flex-direction: column;
            h1,
            p {
                text-align: center;
            }
            svg {
                height: 80px;
                width: 80px;
            }
            p {
                font-size: 18pt;
            }

            .buttons {
                display: flex;
                gap: 10px;
                align-content: center;
                button {
                    margin-top: 20px;
                }
            }
        }
        height: 70%;
        width: 80%;
    }
}

.boundaryErrorDialog {
    overflow: auto;
    gap: 10px;
    background-color: $hmiBackDrop !important;
    padding: 20px;
    height: 70vh;
    width: 450px;
    span{
        user-select: text !important;
    }
}
