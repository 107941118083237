.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2{
        margin: 0;
    }
    .bp3-form-group{
        margin-bottom: 0;
    }
}